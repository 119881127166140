import { useState, useRef, useEffect } from 'react';
import dynamic from 'next/dynamic';
import ReactPlayer from 'react-player';

import styles from './index.module.scss';

const Typography = dynamic(() => import('#app/UI/atoms/typography-updated'));

const PreviewVideoSuccessStories = ({
 id, title, description, urlVideo, thumbnail 
}) => {
  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    setIsPlaying(false);
  }, [id]);

  return (
    <div className={styles.container} onClick={() => setIsPlaying(prev => !prev)}>
      <div className={styles.header}>
        <Typography className={styles.title} tag="p">
          {title}
        </Typography>
        <Typography className={styles.description} tag="p">
          {description}
        </Typography>
      </div>
      <div className={styles.body}>
        {!isPlaying ? (
          <div className={styles.thumbnail}>
            <img src={thumbnail} alt={title} />
          </div>
        ) : (
          <ReactPlayer
            ref={playerRef}
            url={urlVideo}
            playing={isPlaying}
            volume={0.5}
            width="100%"
            height="470px"
          />
        )}
      </div>
    </div>
  );
};

export default PreviewVideoSuccessStories;
